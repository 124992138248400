<template>
  <div class="flex-auto flex-column">
    <div class="flex-row">
      <div class="welcome flex-column align-items-center justify-center">
        <div style="flex:0 0 50px;">
          <el-badge :value="$store.state.loginRes.audit_state==2?'已认证':'未认证'" :type="$store.state.loginRes.audit_state==2?'success':'info'" :hidden="$store.getters.isSpecUser">
            <el-tooltip class="item" effect="dark" content="设置头像" placement="top-start">
              <el-upload
                class="avatar-uploader"
                action=""
                :http-request="setProfile"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload">
                <el-image v-if="imageUrl" :src="imageUrl" class="avatar" fit="cover"></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-tooltip>
          </el-badge>
        </div>
        <div class="flex-row align-items-center justify-center margin-top-5 margin-lr-15">
          <div class="font-size-16 bold  text-overflow-ellipsis-block-2">
            {{ $store.state.loginRes.dwmch||$store.state.loginRes.user_name}}
          </div>
        </div>
        <div class="flex-row align-items-center justify-center margin-top-5 margin-lr-15 border-top padding-top-5" style="width:90%" >
          <div class="font-size-12">部门：{{oaMain.dept_name}} - {{ oaMain.position }}</div>
        </div>
      </div>
      <div class="column flex-auto flex-column" style="margin:15px 0; padding:0; border-radius: 0 10px 10px 0/0 10px 10px 0;">
        <div class="border-bottom padding-tb-10 padding-lr-20">
          便捷操作
        </div>
        <div class="flex-row align-items-center justify-around flex-auto">
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-1.png" /></div>
            <div class="font-size-12">客户拜访</div>
          </div>
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-2.png" /></div>
            <div class="font-size-12">货位管理</div>
          </div>
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-3.png" /></div>
            <div class="font-size-12">业务员管理</div>
          </div>
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-4.png" /></div>
            <div class="font-size-12">销售统计</div>
          </div>
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-5.png" /></div>
            <div class="font-size-12">配送对账</div>
          </div>
          <div class="text-align-center padding-lr-20 link hover-opacity">
            <div><img height="24" src="@/assets/images/data-board-icon/icon-6.png" /></div>
            <div class="font-size-12">销售记录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top-20 flex-auto column">
      <el-row :gutter="20" type="flex">
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.jrps">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">今日配送</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <div class="flex-row justify-between margin-bottom-10">
              <div class="flex-row align-items-center">
                <div class="color-sub">今日配送金额：</div>
                <div>
                  <span class="color-sub">￥</span>
                  <span class="color-orange font-size-20 bold">{{oaMain.jrps.jrpsje}}</span>
                </div>
              </div>
              <div class="flex-row align-items-center">
                <div class="color-sub">今日配送单数：</div>
                <div class="color-orange font-size-20 bold">{{oaMain.jrps.jrpsds}}</div>
              </div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrps.yps}}</span><span class="color-sub">单</span>
                  </div>
                  <div class="data-item-title">已配送</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrps.dps}}</span><span class="color-sub">单</span>
                  </div>
                  <div class="data-item-title">待配送</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span><span class="num">{{oaMain.jrps.ysk}}</span>
                  </div>
                  <div class="data-item-title">已收款</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span><span class="num">{{oaMain.jrps.dzk}}</span>
                  </div>
                  <div class="data-item-title">待收款</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.jrdt">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">今日动态</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrdt.jrfk}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">今日访客</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrdt.xdkh}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">下单客户</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrdt.jrwxd}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">今日未下单</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.jrdt.khzs}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">客户总数</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.wdyj">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">我的业绩</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <div class="flex-row justify-between margin-bottom-10">
              <div class="flex-row align-items-center">
                <div class="color-sub">今日销售：</div>
                <div>
                  <span class="color-sub">￥</span>
                  <span class="color-orange font-size-20 bold">{{oaMain.wdyj.jrxs}}</span>
                </div>
              </div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span>
                    <span class="num">{{oaMain.wdyj.bytc}}</span>
                  </div>
                  <div class="data-item-title">本月提成</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span>
                    <span class="num">{{oaMain.wdyj.byxs}}</span>
                  </div>
                  <div class="data-item-title">本月销售</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span>
                    <span class="num">{{oaMain.wdyj.byjs}}</span>
                  </div>
                  <div class="data-item-title">本月结算</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="color-sub">￥</span>
                    <span class="num">{{oaMain.wdyj.bydhk}}</span>
                  </div>
                  <div class="data-item-title">本月待回款</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.wdgx">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">我的贡献</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="12">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.wdgx.bybfkh}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">本月拜访客户</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.wdgx.bydkxd}}</span><span class="color-sub">单</span>
                  </div>
                  <div class="data-item-title">本月代客下单</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.khzl">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">客户总览</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <div class="flex-row justify-between margin-bottom-10">
              <div class="flex-row align-items-center">
                <div class="color-sub">本月新增客户：</div>
                <div>
                  <span class="color-orange font-size-20 bold">{{oaMain.khzl.byxzkh}}</span>
                  <span class="color-sub">家</span>
                </div>
              </div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.khzl.wdkh}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">我的客户</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.khzl.yzkh}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">优质客户</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.khzl.zzlq}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">资质临期</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="data-item">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.khzl.zzgq}}</span><span class="color-sub">家</span>
                  </div>
                  <div class="data-item-title">资质过期</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item" v-if="oaMain.kczl">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">库存总览</div>
              <div class="custom-btn hover-opacity" color="orange">统计分析</div>
            </div>
            <el-row type="flex" :gutter="20">
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.zssp}}</span>
                  </div>
                  <div class="data-item-title">在售商品</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.hdcx}}</span>
                  </div>
                  <div class="data-item-title">活动促销</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.kcjz}}</span>
                  </div>
                  <div class="data-item-title">库存紧张</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.lqsp}}</span>
                  </div>
                  <div class="data-item-title">临期商品</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.zxsp}}</span>
                  </div>
                  <div class="data-item-title">滞销商品</div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="data-item hover-opacity link">
                  <div class="data-item-num">
                    <span class="num">{{oaMain.kczl.qhsp}}</span>
                  </div>
                  <div class="data-item-title">缺货商品</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">商品销售排行</div>
              <div class="column-tabs ">
                <div :class="{'active':salesRanking.dayType==item.value}" color="orange" v-for="item in dayTypeList" :key="item.value" @click="salesRanking.dayType=item.value;getSalesRanking()">{{item.name}}</div>
              </div>
            </div>
            <div v-if="salesRanking.dayType==4" class="flex-row justify-end align-items-center">
              选择查询时间范围：
              <el-date-picker
                v-model="salesRanking.startDate"
                size="mini"
                type="date"
                placeholder="选择开始日期"
                value-format="yyyy-MM-dd"
                @change="getSalesRanking"
                style="width: 140px;">
              </el-date-picker>
              <span class="margin-lr-5">至</span>
              <el-date-picker
                v-model="salesRanking.endDate"
                size="mini"
                type="date"
                placeholder="选择结束日期"
                value-format="yyyy-MM-dd"
                @change="getSalesRanking"
                style="width: 140px;">
              </el-date-picker>
            </div>
            <div>
              <div class="flex-row border-bottom padding-tb-10" v-for="(item,index) in salesRanking.list" :key="index">
                <div>
                  <el-image class="list-item-pic border" :src="item.img_url" fit="contain">
                    <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                      <img src="@/assets/images/no-pic.png" height="60px"/>
                    </div>
                  </el-image>
                </div>
                <div class="flex-auto margin-left-20">
                  <div class="font-size-16 bold">{{item.spmc}}</div>
                  <div class="flex-row color-sub justify-between margin-top-10">
                    <div>厂家：{{item.sccj}}</div>
                    <div>规格：{{item.gg}}</div>
                    <div>销售金额：<span class="color-orange">￥{{item.xsje}}</span></div>
                    <div>销售数量：<span class="color-orange">{{item.xssl}}</span></div>
                  </div>
                </div>
              </div>
              <el-empty v-if="salesRanking.list.length==0" description="暂无排行数据"></el-empty>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="board-item">
            <div class="flex-row align-items-center justify-between margin-bottom-10">
              <div class="board-item-title-icon font-size-20 bold">客户购买排行</div>
              <div class="column-tabs ">
                <div :class="{'active':customerRanking.dayType==item.value}" color="orange" v-for="item in dayTypeList" :key="item.value" @click="customerRanking.dayType=item.value;getCustomerRanking()">{{item.name}}</div>
              </div>
            </div>
            <div v-if="customerRanking.dayType==4" class="flex-row justify-end align-items-center">
              选择查询时间范围：
              <el-date-picker
                v-model="customerRanking.startDate"
                size="mini"
                type="date"
                placeholder="选择开始日期"
                value-format="yyyy-MM-dd"
                @change="getCustomerRanking"
                style="width: 140px;">
              </el-date-picker>
              <span class="margin-lr-5">至</span>
              <el-date-picker
                v-model="customerRanking.endDate"
                size="mini"
                type="date"
                placeholder="选择结束日期"
                value-format="yyyy-MM-dd"
                @change="getCustomerRanking"
                style="width: 140px;">
              </el-date-picker>
            </div>
            <div>
              <div class="border-bottom padding-tb-10" v-for="(item,index) in customerRanking.list" :key="index">
                  <div class="font-size-16 bold">{{item.dwmc}}</div>
                  <div class="flex-row color-sub justify-between margin-top-10">
                    <div>购买金额：<span class="color-orange">￥{{item.gmje}}</span></div>
                    <div>购买商品种类：<span class="color-orange">{{item.gmzl}}</span></div>
                    <div>下单数：<span class="color-orange">{{item.xds}}</span></div>
                  </div>
              </div>
              <el-empty v-if="salesRanking.list.length==0" description="暂无排行数据"></el-empty>
            </div>
          </div>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import avatar from "@/assets/images/default-photo.png";

export default {
  components: { },
  name: 'UserHome',
  data() {
    return {
     avatar,
     imageUrl: this.$store.state.loginRes.user_profile||avatar,
     oaMain:{},
     dayTypeList:[
      {name:'今日',value:1},
      {name:'昨日',value:2},
      {name:'近30日',value:3},
      {name:'自定义',value:4},
     ],
     salesRanking:{
      dayType:1,
      startDate:'',
      endDate:'',
      list:[],
     },
     customerRanking:{
      dayType:1,
      startDate:'',
      endDate:'',
      list:[],
     }
    }
  },
  computed:{
    checkDate(){
      return (start,end)=>{
        let startDate=new Date(start).getTime(),
          endDate=new Date(end).getTime()
        if(start&&end&&startDate>endDate){
          return false
        }else{
          return true
        }
      }
      
    },
  },
  methods:{
    //获取统计数据
    getOaMain(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/ywy/oa_main',{params})
      .then(res=>{
        if(res.data.code==0){
          this.oaMain=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    getSalesRanking(){
      if(this.salesRanking.dayType==4){
        if(!this.salesRanking.startDate||!this.salesRanking.endDate){
          return false
        }
        if(!this.checkDate(this.salesRanking.startDate,this.salesRanking.endDate)){
          this.$message({message: '开始时间应小于结束时间',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          return false
        }
      }
      let params= {
        ...this.$store.state.basicParams,
        day_type:this.salesRanking.dayType,
        start_date:this.salesRanking.startDate,
        end_date:this.salesRanking.endDate
      }
      this.$instance.get('/ywy/oa_main_spph',{params})
      .then(res=>{
        if(res.data.code==0){
          this.salesRanking.list=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    getCustomerRanking(){
      if(this.customerRanking.dayType==4){
        if(!this.customerRanking.startDate||!this.customerRanking.endDate){
          return false
        }
        if(!this.checkDate(this.customerRanking.startDate,this.customerRanking.endDate)){
          this.$message({message: '开始时间应小于结束时间',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          return false
        }
      }
      let params= {
        ...this.$store.state.basicParams,
        day_type:this.customerRanking.dayType,
        start_date:this.customerRanking.startDate,
        end_date:this.customerRanking.endDate
      }
      this.$instance.get('/ywy/oa_main_gmph',{params})
      .then(res=>{
        if(res.data.code==0){
          this.customerRanking.list=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    //设置头像
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    setProfile(obj){
      console.log(obj)
      const formData = new FormData();
      formData.append( "user_profile" , obj.file );//file 和 后端字段要保持一致
      this.$instance.post('/user/setting_user_profile',formData,
        {
          timeout: 300000,
        } 
      ).then(res=>{
        if(res.data.code==0){
          this.imageUrl = URL.createObjectURL(obj.file);
          this.$store.commit('loginRes',{...this.$store.state.loginRes,user_profile:this.imageUrl})
          try{
            localStorage.setItem('loginRes',crypto.encrypt(this.$store.state.loginRes))
          }catch{
            //
          }
          
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
    },
    
  },
  created(){
    this.getOaMain()
    this.getSalesRanking()
    this.getCustomerRanking()
  },
  mounted(){ 
   
  },
  beforeDestroy() {
  
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .welcome{
  flex:0 0 300px;
  height: 200px;
  background: url('../../assets/images/user-welcome.jpg') center no-repeat;
  background-size:cover;
  color:#fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .avatar {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
  }
 }
 .board-item{
    flex:auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background:#fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border: #f2cbb5 solid 1px;
    border-top: #fd5c0f solid 5px;
    .board-item-title-icon{
      position: relative;
      padding-left: 10px;
    }
    .board-item-title-icon::before{
      content: '';
      position: absolute;
      left: 0px;
      top:50%;
      width: 4px;
      height: 18px;
      margin-top: -9px;
      border-radius: 2px;
      background: #fd5c0f;
    }
  }
  .data-item{
    border: #f2cbb5 solid 1px;
    border-radius: 5px;
    flex:auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    .data-item-num{
      flex: auto;
      padding: 5px;
      .num{
        font-size: 20px;
        font-weight: bold;
      }
    }
    .data-item-title{
      background: #fff6ef;
      color: #fd5c0f;
      border-top: #f2cbb5 solid 1px;
      padding: 5px 0;
    }
  }
  .list-item-pic{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
